import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import Layout from "../../components/layout";
import LeadsTable from "../../components/leads-table";
import useFetchData from "../../components/use-fetch-data";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AdminLayout from "../../components/admin-layout";
import BrokerMultiStepForm from "../../components/broker-multi-step-form";
import BrokerLayout from "../../components/broker-layout";
import withRoleBasedRedirect from "../../components/with-role-based-redirects";
import { useAuth0 } from "@auth0/auth0-react";
import EditDetailsForm from "../../components/edit-details-form";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Spinner } from "react-bootstrap";

const EditBrokerDetailsPage = () => {
	const [brokerIdNew, setBrokerIdNew] = useState();
	const { user } = useAuth0();
	const namespace = "https://quotestar.co/"; // Namespace used in your Auth0 rule/action
	const brokerId = user[`${namespace}user_metadata`]?.airtableRecordId;
	const apiKey = process.env.GATSBY_MY_SECRET_API_KEY; // Ensure you're using the right environment variable for your API key

	useEffect(() => {
		setBrokerIdNew(brokerId);
	}, [brokerId]);

	const {
		data: brokerData,
		loading: loadingBroker,
		error: errorBroker,
	} = useFetchData(
		`/.netlify/functions/fetchAirtableBrokerById?id=${brokerId}`,
		apiKey
	);

	// Since loading and error states are handled within each call, we don't need additional state hooks for them

	// You may need to adjust this if your data structure requires it
	const broker = brokerData ? brokerData : null; // Assuming the broker data is an array and you're interested in the first item

	if (loadingBroker) {
		return (
			<BrokerLayout>
				<div
					style={{ width: "100%", height: "100%" }}
					className="position-relative"
				>
					<div
						style={{ top: "50vh" }}
						className="position-absolute start-50 translate-middle"
					>
						<Spinner
							style={{ width: "6rem", height: "6rem" }}
							animation="border"
							role="status"
							variant="primary"
						>
							<span className="visually-hidden">Loading...</span>
						</Spinner>
					</div>

					<GatsbySeo title="Edit Details" language="en" noindex nofollow />
				</div>
			</BrokerLayout>
		);
	}

	return (
		<BrokerLayout>
			<GatsbySeo title="Edit Details" language="en" noindex nofollow />
			<Row className="bg-med-grey">
				<Col>
					<EditDetailsForm broker={broker} id={brokerIdNew} />
				</Col>
			</Row>
		</BrokerLayout>
	);
};
export default withRoleBasedRedirect(EditBrokerDetailsPage, {
	requiredRole: "Broker",
});

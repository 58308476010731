import React, {
	Suspense,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";
import {
	Form,
	Button,
	Container,
	Row,
	Col,
	ProgressBar,
	ToggleButton,
	ToggleButtonGroup,
} from "react-bootstrap";

import { ImCheckmark } from "react-icons/im";

import { v4 as uuidv4 } from "uuid";

import { Link } from "gatsby";
import { TbLetterF } from "react-icons/tb";
import CategoryComponent from "./category-component";
import SuccessLogo from "../images/info-circle-green.svg";
import FailLogo from "../images/info-circle-red.svg";
import CustomModal from "./custom-modal";

const EditDetailsForm = ({ broker, id }) => {
	const [currentStep, setCurrentStep] = useState(1);
	const [validated, setValidated] = useState(false);
	const [formData, setFormData] = useState({
		// Initialize your form data fields
		companyName: broker?.companyName || "",
		email: broker?.email || "",
		firstName: broker?.firstName || "",

		lastName: broker?.lastName || "",
		categories: broker?.categories || [],
		tradeLicenseNumber: broker?.tradeLicenseNumber || "",
		tradeLicenseExpirationDate: broker?.tradeLicenseExpirationDate || "",
		linkedIn: broker?.linkedIn || "",

		phoneNumber: broker?.phoneNumber || "",
	});
	const [showModal, setShowModal] = useState(false);
	const [modalContent, setModalContent] = useState({
		message: "",
		success: true,
	});

	useEffect(() => {
		const temp = {
			companyName: broker?.companyName || "",
			email: broker?.email || "",
			firstName: broker?.firstName || "",

			lastName: broker?.lastName || "",
			categories: broker?.categories || [],
			tradeLicenseNumber: broker?.tradeLicenseNumber || "",
			tradeLicenseExpirationDate: broker?.tradeLicenseExpirationDate || "",
			linkedIn: broker?.linkedIn || "",

			phoneNumber: broker?.phoneNumber || "",
		};

		setFormData(temp);
	}, [broker]);

	const handlePreferencesChange = (newPreferences) => {
		setFormData({
			...formData,
			preferences: newPreferences,
		});
	};
	const handleYesNoChange = (value, label) => {
		setFormData({
			...formData,
			[label]: value,
		});
	};

	function YourDropdown({ title, label, options }) {
		const optionsArray = options.split(",").map((option) => option.trim());

		return (
			<Form.Group className="mb-3" controlId={label}>
				<Form.Select id={label} name={title} placeholder={title}>
					<option value="">Select an option</option>
					{optionsArray.map((option, index) => (
						<option value={option} key={index}>
							{option}
						</option>
					))}
				</Form.Select>
			</Form.Group>
		);
	}

	function PreferencesSelector() {
		const handleButtonClick = (value) => {
			const currentIndex = formData.preferences.indexOf(value);
			let newSelectedPreferences = [...formData.preferences];

			if (currentIndex === -1) {
				newSelectedPreferences.push(value);
			} else {
				newSelectedPreferences.splice(currentIndex, 1);
			}

			handlePreferencesChange(newSelectedPreferences);
		};

		return (
			<div className="d-flex align-items-center ">
				<>
					{["telephone", "email", "sms"].map((option) => (
						<Form.Check
							className="me-4"
							type="checkbox"
							id={`preference-${option}`}
							label={option.charAt(0).toUpperCase() + option.slice(1)}
							checked={formData.preferences.includes(option)}
							onChange={() => handleButtonClick(option)} // Adjust this function to handle adding/removing from the array
							key={option}
						/>
					))}
				</>
			</div>
		);
	}

	function YesNoSelector({ title, label }) {
		const handleButtonClick = (value) => {
			handleYesNoChange(value, label);
		};

		useEffect(() => {
			// This will call the parent component's update function
			// when the component mounts with the default value.
			if (formData[`${label}`] !== "Yes" && formData[`${label}`] !== "No")
				handleYesNoChange("Yes", label);
		}, []);

		return (
			<div className="d-flex align-items-center">
				<Form.Check
					type="radio"
					id={`${label}-yes`}
					name={label}
					label="Yes"
					value="Yes"
					checked={formData[label] === "Yes"}
					onChange={() => handleYesNoChange("Yes", label)}
					className="mb-2 me-3"
				/>
				<Form.Check
					type="radio"
					id={`${label}-no`}
					name={label}
					label="No"
					value="No"
					checked={formData[label] === "No"}
					onChange={() => handleYesNoChange("No", label)}
					className="mb-2"
				/>
			</div>
		);
	}

	const handleStepSubmit = (event) => {
		event.preventDefault();
		const form = event.currentTarget;
		if (form.checkValidity() === false) {
			setValidated(true);
			event.stopPropagation();
		} else {
			let nextStep = currentStep + 1;
			// Ensure nextStep does not exceed the number of steps
			nextStep = Math.min(nextStep, steps.length);
			setCurrentStep(nextStep);
			setValidated(false);
		}
	};

	const handleBack = () => {
		let previousStep = currentStep - 1;
		// Ensure previousStep does not go below 1
		previousStep = Math.max(previousStep, 1);
		setCurrentStep(previousStep);
		setValidated(false); // Optionally reset validation state when going back
	};
	const handleChange = useCallback((event) => {
		const { name, value, checked, type } = event.target;

		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: type === "checkbox" ? checked : value,
		}));
	}, []);

	const postToServerlessFunction = async () => {
		const endpoint = `/.netlify/functions/updateBrokerDetails?id=${id}`; // Your Netlify function URL
		const apiKey = process.env.GATSBY_MY_SECRET_API_KEY; // Your API key

		try {
			const response = await fetch(endpoint, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"x-api-key": apiKey,
				},
				body: JSON.stringify(formData), // Assume formData is defined elsewhere in your component
			});

			if (!response.ok) {
				const responseData = await response.json();

				throw new Error(`HTTP error! Status: ${response.status}`);
			}

			const responseData = await response.json();
			setModalContent({
				message: "Success! Your details were updated.",
				success: true,
			});
			setShowModal(true);

			return responseData;
		} catch (error) {
			setModalContent({
				message: "An error occurred while updating your details.",
				success: false,
			});
			setShowModal(true);

			return null;
		}
	};

	const handleCloseModal = () => setShowModal(false);

	const handleSubmit = (event) => {
		const form = event.currentTarget;
		if (form.checkValidity() === true) {
			event.preventDefault();
			postToServerlessFunction().then((response) => {});
		}
		event.preventDefault();
		event.stopPropagation();
		setValidated(true);
	};

	const isStepFilled = true;

	const Step1 = React.memo(({ formData, handleChange }) => {
		return (
			<div className=" ">
				<Form.Group
					className="pb-4 d-flex align-items-center"
					controlId="preferences"
				>
					<Form.Label className="me-3 mb-0 pb-0 bold">Preferences: </Form.Label>{" "}
					<PreferencesSelector />
				</Form.Group>

				<Form.Group className="pb-3" controlId="phoneNumber">
					<Form.Label className="bold">Phone number *</Form.Label>
					<Form.Control
						placeholder="+44"
						className="py-3"
						name="phoneNumber"
						value={formData?.phoneNumber}
						onChange={handleChange}
						required
						pattern="\+?[0-9]{1,15}"
						type="tel"
						isInvalid={validated && !formData.phoneNumber}
					/>
					<Form.Control.Feedback type="invalid">
						Please provide a valid phone number.
					</Form.Control.Feedback>
				</Form.Group>
				<p style={{ color: "#8E8EA9" }} className="pb-0 mb-0">
					Start with country code
				</p>
			</div>
		);
	});

	const Step2 = React.memo(({ formData, handleChange }) => {
		return (
			<div className=" ">
				<Form.Group className="pb-3" controlId="name">
					<Form.Label className="bold">Full Name *</Form.Label>
					<Form.Control
						placeholder="Name"
						className="py-3"
						name="name"
						value={formData.name}
						onChange={handleChange}
						required
						type="text"
						isInvalid={validated && !formData.name}
					/>
					<Form.Control.Feedback type="invalid">
						Please provide a name.
					</Form.Control.Feedback>
				</Form.Group>
				<Form.Group className="pb-3" controlId="email">
					<Form.Label className="bold">Email *</Form.Label>
					<Form.Control
						placeholder="Email"
						name="email"
						className="py-3"
						value={formData.email}
						onChange={handleChange}
						required
						pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$"
						type="email"
						isInvalid={validated && !formData.email}
					/>
					<Form.Control.Feedback type="invalid">
						Please provide a valid email address.
					</Form.Control.Feedback>
				</Form.Group>
				<Form.Group className="pb-3" controlId="addressLine1">
					<Form.Label className="bold">Address Line 1 *</Form.Label>
					<Form.Control
						placeholder="Address Line 1"
						className="py-3"
						name="addressLine1"
						value={formData.addressLine1}
						onChange={handleChange}
						required
						type="text"
						isInvalid={validated && !formData.addressLine1}
					/>
					<Form.Control.Feedback type="invalid">
						Please provide a valid Address.
					</Form.Control.Feedback>
				</Form.Group>

				<Form.Group className="pb-3" controlId="townCity">
					<Form.Label className="bold">Town/City *</Form.Label>
					<Form.Control
						placeholder="Town/City"
						className="py-3"
						name="townCity"
						value={formData.townCity}
						onChange={handleChange}
						required
						type="text"
						isInvalid={validated && !formData.townCity}
					/>
					<Form.Control.Feedback type="invalid">
						Please provide a Town/City.
					</Form.Control.Feedback>
				</Form.Group>

				<Form.Group className="pb-3" controlId="county">
					<Form.Label className="bold">County *</Form.Label>
					<Form.Control
						placeholder="County"
						className="py-3"
						name="county"
						value={formData.county}
						onChange={handleChange}
						required
						type="text"
						isInvalid={validated && !formData.county}
					/>
					<Form.Control.Feedback type="invalid">
						Please provide a county.
					</Form.Control.Feedback>
				</Form.Group>

				<Form.Group className="pb-3" controlId="postcode">
					<Form.Label className="bold">Postcode *</Form.Label>
					<Form.Control
						placeholder="Postcode"
						className="py-3"
						name="postcode"
						value={formData.postcode}
						onChange={handleChange}
						required
						type="text"
						isInvalid={validated && !formData.postcode}
					/>
					<Form.Control.Feedback type="invalid">
						Please provide a postcode.
					</Form.Control.Feedback>
				</Form.Group>

				<Form.Group className="pb-3" controlId="insuranceStartDate">
					<Form.Label className="bold">Insurance Start Date *</Form.Label>
					<Form.Control
						placeholder="Date of Birth"
						className="py-3"
						name="insuranceStartDate"
						value={formData.insuranceStartDate}
						onChange={handleChange}
						required
						type="date"
						isInvalid={validated && !formData.insuranceStartDate}
					/>
					<Form.Control.Feedback type="invalid">
						Please provide a valid date.
					</Form.Control.Feedback>
				</Form.Group>
				<p style={{ color: "#8E8EA9" }} className="">
					Start Date must be greater than current date.
				</p>

				{/* <Form.Group className="pb-3" controlId="quoteReference">
					<Form.Label className="bold">Quote Reference</Form.Label>
					<Form.Control
						placeholder="Quote reference"
						className="py-3"
						name="quoteReference"
						value={formData.quoteReference}
						onChange={handleChange}
						type="text"
					/>
				</Form.Group> */}
			</div>
		);
	});

	const Step3 = React.memo(({ formData, handleChange }) => {
		return (
			<div className="mt-5 ">
				<Form.Group className="mt-4" controlId="email">
					<Form.Label>Email Address</Form.Label>
					<Form.Control
						placeholder="Email"
						name="email"
						value={formData.email}
						onChange={handleChange}
						required
						pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$"
						type="email"
						isInvalid={validated && !formData.email}
					/>
					<Form.Control.Feedback type="invalid">
						Please provide a valid email address.
					</Form.Control.Feedback>
				</Form.Group>
				<Form.Group className="mt-4" controlId="sms">
					<Form.Label>SMS</Form.Label>
					<Form.Control
						placeholder="SMS"
						name="sms"
						value={formData.sms}
						onChange={handleChange}
						required
						pattern="\+?[0-9]{1,15}"
						type="tel"
						isInvalid={validated && !formData.sms}
					/>
					<Form.Control.Feedback type="invalid">
						Please provide a valid SMS number.
					</Form.Control.Feedback>
				</Form.Group>
				<PreferencesSelector />
				<Form.Group className="mt-4" controlId="telephone">
					<Form.Control
						placeholder="telephone"
						name="telephone"
						value={formData.telephone}
						onChange={handleChange}
						required
						pattern="\+?[0-9]{1,15}"
						type="tel"
						// Provide feedback only if the input was touched
						isInvalid={validated && !formData.telephone}
					/>
					<Form.Control.Feedback type="invalid">
						Please provide a valid telephone number.
					</Form.Control.Feedback>
				</Form.Group>
				<div>
					<label
						htmlFor="Do you have  business premises?"
						className=" fs-5 pb-4 text-start"
					>
						Do you have business premises?
					</label>
					<YesNoSelector
						title="Do you have  business premises?"
						label="do you have  business premises?"
					/>
				</div>
			</div>
		);
	});

	const steps = [
		{
			component: Step1,
			condition: true,
		},
		{
			component: Step2,
			condition: true,
		},
		{
			component: Step3,
			condition: true,
		},
		{
			component: Step3,
			condition: true,
		},
	];

	const renderStepList = () => {
		return (
			<div style={{ display: "flex", justifyContent: "center" }}>
				{[1, 2, 3, 4, 5].map((step) => (
					<div
						key={step}
						style={{
							display: "flex",
							alignItems: "center",
						}}
						className="position-relative"
					>
						<a
							href="#"
							className="text-decoration-none d-block"
							// onClick={() => handleStepClick(step)}
						>
							{currentStep === step && (
								<div
									className="text-white fw-bold"
									style={{
										width: "20px",
										height: "20px",
										borderRadius: "50%",
										backgroundColor:
											currentStep === step ? "#255DAB" : "#96B6E1",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
									}}
								>
									{isStepFilled ? (
										<span style={{ fontSize: "15px" }}>
											<ImCheckmark className="text-white pb-1" />
										</span>
									) : (
										step
									)}
								</div>
							)}
							{currentStep !== step && !isStepFilled && step !== 5 && (
								<div
									className="text-white fw-bold"
									style={{
										width: "20px",
										height: "20px",
										borderRadius: "50%",
										backgroundColor: "#CF3333",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
									}}
								>
									{isStepFilled ? (
										<span style={{ fontSize: "15px" }}>
											<ImCheckmark className="text-white pb-1" />
										</span>
									) : (
										step
									)}
								</div>
							)}
							{((currentStep !== step && isStepFilled) ||
								(currentStep !== step && step === 5)) && (
								<div
									className="text-white fw-bold"
									style={{
										width: "20px",
										height: "20px",
										borderRadius: "50%",
										backgroundColor: isStepFilled ? "#2DA8A5" : "#96B6E1",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
									}}
								>
									{isStepFilled ? (
										<span style={{ fontSize: "15px" }}>
											<ImCheckmark className="text-white pb-1" />
										</span>
									) : (
										step
									)}
								</div>
							)}
						</a>
						{step !== 5 && (
							<div
								style={{
									width: "170px",
									height: "1px",
									background: "rgba(0, 0, 0, 0.32)",
								}}
							></div>
						)}
					</div>
				))}
			</div>
		);
	};

	return (
		<section>
			<div className="position-relative" style={{ minHeight: "100vh" }}>
				<CustomModal show={showModal} onHide={handleCloseModal}>
					<div className="d-block">
						{modalContent.success === true && (
							<div>
								<img
									src={SuccessLogo}
									alt="Success Logo"
									style={{
										width: "70px",
									}}
								/>

								<p className="mt-3 Jakarta-Bold">{modalContent.message}</p>
								<div>
									<Button
										size="lg"
										className="gradient-button px-7 w-100 py-3 mt-3 text-uppercase"
										as={Link}
										to={`/broker/profile`}
									>
										Go Back
									</Button>
								</div>
							</div>
						)}
						{modalContent.success === false && (
							<div>
								<img
									src={FailLogo}
									alt="Fail Logo"
									style={{
										width: "70px",
									}}
								/>
								<p className="mt-3 Jakarta-Bold">{modalContent.message}</p>
								<div>
									<Button
										size="lg"
										className="gradient-button px-7 w-100 py-3 mt-3 text-uppercase"
										onClick={handleCloseModal}
									>
										Close
									</Button>
								</div>
							</div>
						)}
					</div>
				</CustomModal>
				<Row className="justify-content-center">
					<Col>
						<h1 className="mb-4">{`${
							currentStep === 1
								? "Broker details"
								: currentStep === 2
								? "Company Details"
								: currentStep === 3
								? "Leads Filter"
								: "Finish & Preview"
						}`}</h1>
					</Col>
				</Row>
				<Row className="justify-content-center">
					<Col>
						<div
							style={{
								boxShadow: "0px 3.75px 12px 0px rgba(8, 15, 52, 0.06)",
								borderRadius: "24px",
							}}
							className="bg-white "
						>
							<Row className="justify-content-center">
								<Col
									className="px-5 py-7"
									style={{
										borderRight: "1px solid #B9B9B9",
										minHeight: "700px",
									}}
									lg={4}
								>
									<div>
										<Row>
											<Col xs={3}>
												<div
													style={{
														borderRadius: "100%",
														width: "60px",
														height: "60px",
														backgroundColor:
															currentStep === 1 ? "#1470D6" : "#2DAF49",
													}}
													className=" d-flex align-items-center justify-content-center"
												>
													<p className="pb-0 mb-0 text-white fs-2 Jakarta-Bold">
														1
													</p>
												</div>
												<div
													style={{
														marginLeft: "30px",
														height: "100px", // Adjust the height as needed
														width: "1px", // Line thickness
														backgroundImage: `linear-gradient(${
															currentStep === 1 ? "#1470D6" : "#2DAF49"
														} 0%, ${
															currentStep === 1 ? "#1470D6" : "#2DAF49"
														} 50%, transparent 50%, transparent 100%)`,
														backgroundSize: "2px 20px", // First value is line thickness, second value controls dash length and spacing
													}}
												/>
											</Col>
											<Col xs={8}>
												<h2
													style={{
														color: currentStep === 1 ? "#1470D6" : "#2DAF49",
													}}
													className="fs-5 mb-3"
												>
													Step 1
												</h2>
												<p className="fs-5 Jakarta-Bold pb-1 mb-0 text-heading-color">
													Broker details
												</p>
												<p className="text-schema-grey pt-0 mt-0 pb-0 mb-0">
													Change Broker Contact Details
												</p>
											</Col>
										</Row>
									</div>
									<div>
										<Row>
											<Col xs={3}>
												<div
													style={{
														borderRadius: "100%",
														width: "60px",
														height: "60px",
														backgroundColor:
															currentStep < 2
																? "#F5F6FA"
																: currentStep === 2
																? "#1470D6"
																: "#2DAF49",
													}}
													className=" d-flex align-items-center justify-content-center"
												>
													<p
														style={{
															color:
																currentStep < 2
																	? "#1470D6"
																	: currentStep === 2
																	? "white"
																	: "white",
														}}
														className="pb-0 mb-0  fs-2 Jakarta-Bold"
													>
														2
													</p>
												</div>
												<div
													style={{
														marginLeft: "30px",
														height: "100px", // Adjust the height as needed
														width: "1px", // Line thickness
														backgroundImage: `linear-gradient(${
															currentStep < 2
																? "#E1E5EA"
																: currentStep === 2
																? "#1470D6"
																: "#2DAF49"
														} 0%, ${
															currentStep < 2
																? "#E1E5EA"
																: currentStep === 2
																? "#1470D6"
																: "#2DAF49"
														} 50%, transparent 50%, transparent 100%)`,
														backgroundSize: "2px 20px", // First value is line thickness, second value controls dash length and spacing
													}}
												/>
											</Col>
											<Col xs={8}>
												<h2
													style={{
														color:
															currentStep < 2
																? "#767676"
																: currentStep === 2
																? "#1470D6"
																: "#2DAF49",
													}}
													className="fs-5 mb-3"
												>
													Step 2
												</h2>
												<p className="fs-5 Jakarta-Bold pb-1 mb-0 text-heading-color">
													Company Details
												</p>
												<p className="text-schema-grey pt-0 mt-0 pb-0 mb-0">
													Enter Company details
												</p>
											</Col>
										</Row>
									</div>
									<div>
										<Row>
											<Col xs={3}>
												<div
													style={{
														borderRadius: "100%",
														width: "60px",
														height: "60px",
														backgroundColor:
															currentStep < 3
																? "#F5F6FA"
																: currentStep === 3
																? "#1470D6"
																: "#2DAF49",
													}}
													className=" d-flex align-items-center justify-content-center"
												>
													<p
														style={{
															color:
																currentStep < 3
																	? "#1470D6"
																	: currentStep === 3
																	? "white"
																	: "white",
														}}
														className="pb-0 mb-0  fs-2 Jakarta-Bold"
													>
														3
													</p>
												</div>
												<div
													style={{
														marginLeft: "30px",
														height: "100px", // Adjust the height as needed
														width: "1px", // Line thickness
														backgroundImage: `linear-gradient(${
															currentStep < 3
																? "#E1E5EA"
																: currentStep === 3
																? "#1470D6"
																: "#2DAF49"
														} 0%, ${
															currentStep < 3
																? "#E1E5EA"
																: currentStep === 3
																? "#1470D6"
																: "#2DAF49"
														} 50%, transparent 50%, transparent 100%)`,
														backgroundSize: "2px 20px", // First value is line thickness, second value controls dash length and spacing
													}}
												/>
											</Col>
											<Col xs={8}>
												<h2
													style={{
														color:
															currentStep < 3
																? "#767676"
																: currentStep === 3
																? "#1470D6"
																: "#2DAF49",
													}}
													className="fs-5 mb-3"
												>
													Step 3
												</h2>
												<p className="fs-5 Jakarta-Bold pb-1 mb-0 text-heading-color">
													Leads Filter
												</p>
												<p className="text-schema-grey pt-0 mt-0 pb-0 mb-0">
													Add Additional Notes and type of leads
												</p>
											</Col>
										</Row>
									</div>
									<div>
										<Row>
											<Col xs={3}>
												<div
													style={{
														borderRadius: "100%",
														width: "60px",
														height: "60px",
														backgroundColor:
															currentStep < 4
																? "#F5F6FA"
																: currentStep === 4
																? "#1470D6"
																: "#2DAF49",
													}}
													className=" d-flex align-items-center justify-content-center"
												>
													<p
														style={{
															color:
																currentStep < 4
																	? "#1470D6"
																	: currentStep === 4
																	? "white"
																	: "white",
														}}
														className="pb-0 mb-0  fs-2 Jakarta-Bold"
													>
														4
													</p>
												</div>
												{/* <div
														style={{
															marginLeft: "30px",
															height: "100px", // Adjust the height as needed
															width: "2px", // Line thickness
															backgroundImage:
																"linear-gradient(#000 0%, #000 50%, transparent 50%, transparent 100%)",
															backgroundSize: "3px 20px", // First value is line thickness, second value controls dash length and spacing
														}}
													/> */}
											</Col>
											<Col xs={8}>
												<h2
													style={{
														color:
															currentStep < 4
																? "#767676"
																: currentStep === 4
																? "#1470D6"
																: "#2DAF49",
													}}
													className="fs-5 mb-3"
												>
													Step 4
												</h2>
												<p className="fs-5 Jakarta-Bold pb-1 mb-0 text-heading-color">
													Finish & Preview
												</p>
												<p className="text-schema-grey pt-0 mt-0 pb-0 mb-0">
													Review your information
												</p>
											</Col>
										</Row>
									</div>
								</Col>
								<Col className="px-5 py-7" lg={8}>
									<Form
										className={`${currentStep === 4 ? "w-90" : "w-60"} mx-auto`}
										noValidate
										validated={validated}
										onSubmit={
											currentStep ===
											steps.filter((step) => step.condition).length
												? handleSubmit
												: handleStepSubmit
										}
									>
										{currentStep === 1 && (
											<div className=" ">
												<Form.Group className="pb-3" controlId="firstName">
													<Form.Label className="bold">First Name *</Form.Label>
													<Form.Control
														placeholder="i.e. Zara"
														className="py-3"
														name="firstName"
														value={formData.firstName}
														onChange={handleChange}
														required
														type="text"
														isInvalid={validated && !formData.firstName}
													/>
													<Form.Control.Feedback type="invalid">
														Please provide a name.
													</Form.Control.Feedback>
												</Form.Group>
												<Form.Group className="pb-3" controlId="lastName">
													<Form.Label className="bold">Last Name *</Form.Label>
													<Form.Control
														placeholder="i.e. Vornax"
														className="py-3"
														name="lastName"
														value={formData.lastName}
														onChange={handleChange}
														required
														type="text"
														isInvalid={validated && !formData.lastName}
													/>
													<Form.Control.Feedback type="invalid">
														Please provide a name.
													</Form.Control.Feedback>
												</Form.Group>
												<Form.Group className="pb-3" controlId="email">
													<Form.Label className="bold">Email *</Form.Label>
													<Form.Control
														placeholder="@email.com"
														name="email"
														className="py-3"
														value={formData.email}
														onChange={handleChange}
														required
														pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$"
														type="email"
														isInvalid={validated && !formData.email}
													/>
													<Form.Control.Feedback type="invalid">
														Please provide a valid email address.
													</Form.Control.Feedback>
												</Form.Group>
												<Form.Group className="pb-3" controlId="phoneNumber">
													<Form.Label className="bold">Phone *</Form.Label>
													<Form.Control
														placeholder="i.e. +41"
														className="py-3"
														name="phoneNumber"
														value={formData?.phoneNumber}
														onChange={handleChange}
														required
														pattern="\+?[0-9]{1,15}"
														type="tel"
														isInvalid={validated && !formData.phoneNumber}
													/>
													<Form.Control.Feedback type="invalid">
														Please provide a valid phone number.
													</Form.Control.Feedback>
												</Form.Group>
											</div>
										)}
										{currentStep === 2 && (
											<div className=" ">
												<Form.Group className="pb-3" controlId="companyName">
													<Form.Label className="bold">
														Broker Company name *
													</Form.Label>
													<Form.Control
														placeholder="i.e. XYZ"
														className="py-3"
														name="companyName"
														value={formData.companyName}
														onChange={handleChange}
														required
														type="text"
														isInvalid={validated && !formData.companyName}
													/>
													<Form.Control.Feedback type="invalid">
														Please provide a Broker Company Name.
													</Form.Control.Feedback>
												</Form.Group>
												<Form.Group
													className="pb-3"
													controlId="tradeLicenseNumber"
												>
													<Form.Label className="bold">
														Trade license No. *
													</Form.Label>
													<Form.Control
														placeholder="13874981275"
														className="py-3"
														name="tradeLicenseNumber"
														value={formData.tradeLicenseNumber}
														onChange={handleChange}
														required
														type="text"
														isInvalid={
															validated && !formData.tradeLicenseNumber
														}
													/>
													<Form.Control.Feedback type="invalid">
														Please provide trade license no.
													</Form.Control.Feedback>
												</Form.Group>

												<Form.Group
													className="pb-3"
													controlId="tradeLicenseExpirationDate"
												>
													<Form.Label className="bold">
														Trade License expiration date *
													</Form.Label>
													<Form.Control
														placeholder="Trade License expiration date"
														className="py-3"
														name="tradeLicenseExpirationDate"
														value={formData.tradeLicenseExpirationDate}
														onChange={handleChange}
														required
														type="date"
														isInvalid={
															validated && !formData.tradeLicenseExpirationDate
														}
													/>
													<Form.Control.Feedback type="invalid">
														Please provide a valid date.
													</Form.Control.Feedback>
												</Form.Group>
												<Form.Group className="pb-3" controlId="linkedIn">
													<Form.Label className="bold">LinkedIn *</Form.Label>
													<Form.Control
														placeholder="https://www.linkedin.com"
														className="py-3"
														name="linkedIn"
														value={formData.linkedIn}
														onChange={handleChange}
														required
														type="text"
														isInvalid={validated && !formData.linkedIn}
													/>
													<Form.Control.Feedback type="invalid">
														Please provide LinkedIn URL.
													</Form.Control.Feedback>
												</Form.Group>
												{/* <Form.Group className="pb-3" controlId="facebook">
													<Form.Label className="bold">Facebook *</Form.Label>
													<Form.Control
														placeholder="https://www.facebook.com"
														className="py-3"
														name="facebook"
														value={formData.facebook}
														onChange={handleChange}
														required
														type="text"
														isInvalid={validated && !formData.facebook}
													/>
													<Form.Control.Feedback type="invalid">
														Please provide Facebook URL.
													</Form.Control.Feedback>
												</Form.Group> */}
											</div>
										)}
										{currentStep === 3 && (
											<div className=" ">
												<CategoryComponent
													formData={formData}
													setFormData={setFormData}
												/>
											</div>
										)}
										{currentStep === 4 && (
											<div className=" ">
												<Row className="pb-4">
													<Col lg={3}>
														<h3 className="text-schema-grey fs-6">
															Broker Name
														</h3>
														<p className="Jakarta-Bold text-heading-color ">
															{formData.firstName} {formData.lastName}
														</p>
													</Col>
													<Col lg={3}>
														<h3 className="text-schema-grey fs-6">
															Broker Email
														</h3>
														<p className="Jakarta-Bold text-heading-color ">
															{formData.email}
														</p>
													</Col>
													<Col lg={3}>
														<h3 className="text-schema-grey fs-6">
															Phone Number
														</h3>
														<p className="Jakarta-Bold text-heading-color ">
															{formData.phoneNumber}
														</p>
													</Col>
												</Row>
												<Row className="pb-4">
													<Col lg={3}>
														<h3 className="text-schema-grey fs-6">
															Company Name
														</h3>
														<p className="Jakarta-Bold text-heading-color ">
															{formData.companyName}
														</p>
													</Col>
													<Col lg={3}>
														<h3 className="text-schema-grey fs-6">
															License No.
														</h3>
														<p className="Jakarta-Bold text-heading-color ">
															{formData.tradeLicenseNumber}
														</p>
													</Col>
													<Col lg={3}>
														<h3 className="text-schema-grey fs-6">
															License Expiration Date
														</h3>
														<p className="Jakarta-Bold text-heading-color ">
															{formData.tradeLicenseExpirationDate}
														</p>
													</Col>
													<Col lg={3}>
														<h3 className="text-schema-grey fs-6">
															Social Links
														</h3>
														<p className=" mb-0 pb-1">{formData.linkedIn}</p>
														{/* <p className=" ">{formData.facebook}</p> */}
													</Col>
												</Row>
												<Row>
													<Col>
														{" "}
														<div
															style={{
																background: "#F5F6FA",
																minHeight: "250px",
																borderRadius: "6px",
															}}
															className="p-3"
														>
															{formData.categories.map((category, index) => (
																<Button
																	key={index}
																	variant="outline-primary"
																	style={{
																		margin: "5px",
																		borderRadius: "100px",
																		cursor: "default",
																	}}
																	className="px-3"
																>
																	{category}
																</Button>
															))}
														</div>
													</Col>
												</Row>
											</div>
										)}
										{currentStep !== 4 && (
											<div className="mt-2 ">
												{currentStep <
												steps.filter((step) => step.condition).length ? (
													<Button
														size="lg"
														className=" gradient-button px-5 w-100 py-3 mt-3 text-uppercase"
														type="submit"
													>
														Next
													</Button>
												) : (
													<Button
														size="lg"
														className=" gradient-button px-5 w-100 py-3 mt-3 text-uppercase"
														type="submit"
													>
														Submit
													</Button>
												)}
												{currentStep > 1 && (
													<Button
														size="lg"
														className="d-block  px-5 w-100 py-3 mt-3 text-uppercase"
														variant="outline-heading-color border-heading-color"
														onClick={handleBack}
													>
														Back
													</Button>
												)}
											</div>
										)}
										{currentStep === 4 && (
											<div className="mt-4 text-end ">
												<Button
													size="lg"
													className=" px-5 me-3 py-3 mt-3 text-uppercase"
													variant="outline-heading-color border-heading-color"
													onClick={handleBack}
												>
													Back
												</Button>
												<Button
													size="lg"
													className=" gradient-button px-7  py-3 mt-3 text-uppercase"
													type="submit"
												>
													Save All
												</Button>
											</div>
										)}
									</Form>
								</Col>
							</Row>
						</div>
					</Col>
				</Row>
			</div>
		</section>
	);
};

export default EditDetailsForm;
